import React from "react";
import GameContainer from './components/GameContainer';

function App() {
	return (
		<div className="App">
			<GameContainer />
		</div>
	);
}

export default App;
